import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "the-true-store",
      "style": {
        "position": "relative"
      }
    }}>{`The True Store`}<a parentName="h1" {...{
        "href": "#the-true-store",
        "aria-label": "the true store permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "why",
      "style": {
        "position": "relative"
      }
    }}>{`Why`}<a parentName="h2" {...{
        "href": "#why",
        "aria-label": "why permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We’ve lifted the veil in front of our browser, kissed smart contracts from the console and even deployed our own special vows, because everything is interconnected. Now it’s time to see why not even death can part us. Is it really true that a smart contract is forever? How does its storage actually work? What does it mean to delegate access to this forever-store? Is there such a thing as a “private” variable on a public network like Ethereum?`}</p>
    <p>{`All this and more lies in wait for us as we continue our journey into shared truth.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Ensure that you still have some Sepolia ETH left in your account.`}</li>
      <li parentName="ol">{`Review Remix and the work we did there last week. We’ll only use it once, at the end of our session today, but it’s still worth being familiar with.`}</li>
      <li parentName="ol">{`Sit and breathe quietly for at least 15 minutes.`}</li>
    </ol>
    <h2 {...{
      "id": "application",
      "style": {
        "position": "relative"
      }
    }}>{`Application`}<a parentName="h2" {...{
        "href": "#application",
        "aria-label": "application permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We’re going to investigate storage on Ethereum by completing two Ethernaut challenges: Delegation and Vault. This is going to take us into a much lower-level look at some of the operations of the Ethereum Virtual Machine, and the different “slots” associated with each contract. We may even try to pick apart the would from the tries. If time allows, we’ll also attack Privacy.`}</p>
    <p>{`After using some fun new web3 methods to investigate what each contract hides, we’ll finish the session off with another contract of our own to attack the Vault like kamikazes, as well as discussing compilers and companions.`}</p>
    <h2 {...{
      "id": "summation",
      "style": {
        "position": "relative"
      }
    }}>{`Summation`}<a parentName="h2" {...{
        "href": "#summation",
        "aria-label": "summation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`In this session, you will have learned:`}</p>
    <ol>
      <li parentName="ol">{`How low-level calls work in the EVM and the weird and wonderful, though dangerous, things that they can be used to achieve.`}</li>
      <li parentName="ol">{`How information gets stored in smart contracts, and therefore in Ethereum as a whole.`}</li>
      <li parentName="ol">{`What makes the information both public and permanent.`}</li>
      <li parentName="ol">{`More about the web3 API methods and how to use them to craft specific kinds of transactions, directly from your browser.`}</li>
      <li parentName="ol">{`What compilers do and how to use different versions of them.`}</li>
    </ol>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/9atqMRJLEoU" mdxType="Video" />
    <Aligner center mdxType="Aligner">
      <Image alt="John of Patmos" src="/images/build/sealed_book.jpg" mdxType="Image" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      